<template>
  <div class="container" v-if="dataReady">
    <h1>Datenbanken</h1>
    <div class="row">
      <p>
        Im Experimentiermodul „Datenbanken“ haben Sie einen kurzen Einblick in
        die Datenbanksprache SQL (Structured Query Language), Methoden zum
        Hinzufügen und Abfragen von Daten, sowie wesentlicher DBMS-Anweisungen
        und Funktionen, wobei besonderes Augenmerk auf die Syntax gelegt wurde,
        gekriegt, und dabei in steigender Komplexität Befehle in dieser Sprache
        formuliert, um konkrete Probleme zu lösen
      </p>
      <p>
        Datenbanken ermöglichen die Erfassung, Speicherung und Bereitstellung
        verwandter, strukturierten Daten mit wohldefinierten Datenformaten. Sie
        unterstützen die effiziente Abfrage, Veränderung und Analyse dieser
        Daten, um Informationsbedürfnisse in verschiedenen Anwendungsbereichen
        zu erfüllen. Durch den Einsatz von Datenbankmanagementsystemen (DBMS)
        wird die Integrität, Sicherheit und Zugänglichkeit der Daten
        gewährleistet.
      </p>

      <p>
        In diesem Experimentiermodul haben Sie viele Aufgaben zur Verwaltung der
        Restaurants gelöst.
      </p>
      <table class="main-table">
        <thead>
          <tr>
            <th>Aufgabe</th>
            <th>Beschreibung</th>
            <th>Richtige Antwort</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.1</td>
            <td>
              Wählen Sie alle Restaurants aus der restaurants-Tabelle aus, die
              Burger servieren.
            </td>
            <td>SELECT * FROM RESTAURANTS WHERE ESSEN = 'BURGER';</td>
          </tr>
          <tr>
            <td>1.2</td>
            <td>
              Wählen Sie den Namen des Restaurants aus der restaurants-Tabelle
              aus, wo die ID (Primärschlüssel) gleich 5 ist.
            </td>
            <td>SELECT NAME FROM RESTAURANTS WHERE ID = 5;</td>
          </tr>
          <tr>
            <td>1.3</td>
            <td>
              Wählen Sie alle italienischen Restaurants aus der
              restaurants-Tabelle aus.
            </td>
            <td>SELECT * FROM RESTAURANTS WHERE ESSEN = 'ITALIENISCH';</td>
          </tr>
          <tr>
            <td>2.1</td>
            <td>
              Wählen Sie alle amerikanischen Restaurants aus der
              restaurants-Tabelle aus, die Burger servieren.
            </td>
            <td>
              SELECT * FROM RESTAURANTS WHERE TYP = 'AMERIKANISCH' AND ESSEN =
              'BURGER';
            </td>
          </tr>
          <tr>
            <td>2.2</td>
            <td>
              Wählen Sie alle Restaurants aus der restaurants-Tabelle aus, die
              sich in den Postleitzahlen 1010 oder 1020 befinden.
            </td>
            <td>SELECT * FROM RESTAURANTS WHERE PLZ = 1010 OR PLZ = 1020;</td>
          </tr>
          <tr>
            <td>3.1</td>
            <td>
              Erstellen Sie eine Tabelle für ein neues Restaurant namens "Tacos
              Paradise" mit ID, E-Mail, PLZ, Typ und Essen.
            </td>
            <td>
              CREATE TABLE TACOS_PARADISE (ID INT PRIMARY KEY, EMAIL
              VARCHAR(50), PLZ INT, TYP VARCHAR(50), ESSEN VARCHAR(50));
            </td>
          </tr>
          <tr>
            <td>3.2</td>
            <td>
              Fügen Sie Daten für "Tacos Paradise" in die Tabelle ein. ID:
              12345, E-Mail: tacos@paradise.com, PLZ: 1050, Essen: Tacos.
            </td>
            <td>
              INSERT INTO TACOS_PARADISE VALUES (12345, 'TACOS@PARADISE.COM',
              1050, 'MEXIKANISCH', 'TACOS');
            </td>
          </tr>
          <tr>
            <td>3.3</td>
            <td>
              Ändern Sie den Standort von "Tacos Paradise" von 1050 auf 1070.
            </td>
            <td>UPDATE TACOS_PARADISE SET PLZ = 1070 WHERE ID = 12345;</td>
          </tr>
          <tr>
            <td>3.4</td>
            <td>
              Löschen Sie die Daten von "Tacos Paradise" aus der Tabelle
              "restaurants".
            </td>
            <td>DELETE FROM RESTAURANTS WHERE ID = 12345;</td>
          </tr>
        </tbody>
      </table>
      <p></p>
      <p></p>
      <p>
        Unten sehen Sie die Anzahl der Fehler in den einzelnen Aufgaben bzw.
        deren Teilaufgaben.
      </p>

      <table>
        <tr class="survey-row header">
          <th>
            Aufgabe 1 Fehleranzahl
            <table>
              <tr class="survey-row dark">
                <th>A1.1</th>
                <th>A1.2</th>
                <th>A1.3</th>
              </tr>
              <tr class="survey-row light">
                <td>{{ myDatabaseResult.results.A11 }}</td>
                <td>{{ myDatabaseResult.results.A12 }}</td>
                <td>{{ myDatabaseResult.results.A12 }}</td>
              </tr>
            </table>
          </th>

          <th>
            Aufgabe 2 Fehleranzahl
            <table>
              <tr class="survey-row dark">
                <th>A2.1</th>
                <th>A2.2</th>
              </tr>
              <tr class="survey-row light">
                <td>{{ myDatabaseResult.results.A21 }}</td>
                <td>{{ myDatabaseResult.results.A22 }}</td>
              </tr>
            </table>
          </th>

          <th>
            Aufgabe 3 Fehleranzahl
            <table>
              <tr class="survey-row dark">
                <th>A3.1</th>
                <th>A3.2</th>
                <th>A3.3</th>
                <th>A3.4</th>
              </tr>
              <tr class="survey-row light">
                <td>{{ myDatabaseResult.results.A31 }}</td>
                <td>{{ myDatabaseResult.results.A32 }}</td>
                <td>{{ myDatabaseResult.results.A33 }}</td>
                <td>{{ myDatabaseResult.results.A34 }}</td>
              </tr>
            </table>
          </th>
        </tr>
      </table>
    </div>

    <div class="btn-container">
      <div class="custom-btn-secondary" @click="this.experimentBackClicked()">
        Zurück
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["experiment-back"],
  data() {
    return {
      dataReady: false,
      myDatabaseResult: {},
    };
  },
  async mounted() {
    await this.getMyResultsDatabase();
    this.dataReady = true;
  },
  methods: {
    async getMyResultsDatabase() {
      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL + "/api/inf-database/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        this.myDatabaseResult = res[0];
      } catch (error) {
        console.log(error);
      }
    },
    experimentBackClicked() {
      this.$emit("experiment-back");
    },
  },
};
</script>

<style scoped>
.main-table th:nth-child(2),
.main-table td:nth-child(2),
.main-table th:nth-child(3),
.main-table td:nth-child(3) {
  text-align: left;
}
.custom-btn,
.custom-btn-secondary {
  float: right;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th,
td {
  border: 1px solid black;
  text-align: center;
  padding: 8px;
}

/* Adjustments for nested tables */
table th table {
  width: 100%;
}

table th table th,
table th table td {
  width: 200px;
  padding: 8px;
  box-sizing: border-box;
}

th {
  background-color: rgb(70, 110, 165);
  color: white;
}

td {
  background-color: rgb(173, 201, 230);
  color: black;
}

.survey-row.dark th {
  background-color: rgb(101, 133, 167);
}

.survey-row.light td {
  background-color: rgb(222, 218, 218);
}

.header {
  background: rgb(70, 110, 165);
  color: white;
}
</style>
